<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">

        <!-- logo -->
        <b-link class="brand-logo">
          <Logo/>
        </b-link>

        <b-overlay :show="isRedirectingToLogin" rounded="sm">
          <template #overlay>
            <div class="text-center">
              <b-spinner variant="success" style="width: 1.5rem; height: 1.5rem;" />
              <p id="cancel-label">Redirecting to login...</p>
            </div>
          </template>

          <b-card-title class="mb-1">
            {{ $t('Reset Password') }} 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('Your new password must be different from previously used passwords')}}
          </b-card-text>
          <b-card-text class="mb-2">
            <span v-if="isValidatingToken">
              {{ $t('Validating attemp')}} <b-spinner variant="info" small />
            </span>
          </b-card-text>

          <!-- form -->
          <validation-observer ref="resetPasswordForm" v-slot="{ invalid }">
            <b-form
              method="POST"
              class="auth-reset-password-form mt-2"
              @submit.prevent="sendResetPassword"
            >

              <!-- password -->
              <b-form-group
                :label="$t('New Password')"
                label-for="reset-password-new"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required|min:6" vid="password"
                >
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null" >
                    <b-form-input
                      id="reset-password-new"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label-for="reset-password-confirm"
                :label="$t('Confirm new Password')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="cPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="reset-password-confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                block
                type="submit"
                variant="primary"
                :disabled="invalid || !tokenIsValid || isSettingNewPassword"
              >
                <span v-if="isSettingNewPassword">
                  <b-spinner variant="success" style="width: 1.5rem; height: 1.5rem;" /> {{$t('Setting new password')}}
                </span>
                <span v-if="!isSettingNewPassword">
                  {{ $t('Set New Password') }} <feather-icon icon="LockIcon" />
                </span>
              </b-button>
            </b-form>
          </validation-observer>

          <div class="d-flex justify-content-between mt-2">
            <b-link :to="{name:'auth-forgot-password'}">
              <!-- 📧 Retry request -->
              </b-link>
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> {{ $t('Back to login') }}
            </b-link>
          </div>
        </b-overlay>
      </b-card>
    <!-- /Reset Password v1 -->
    </div>
  </div>

</template>

<script>

import { mapActions, mapMutations } from 'vuex'
import Logo from '@/modules/layouts/components/Logo.vue'
import { required } from '@validations'
import { showAlertMessage } from '@/helpers/helpers'
import { localize } from "vee-validate";
export default {
  components: {
    Logo,
  },
  async mounted() {
    this.validateQueryParams()
    this.checkValidateQueryParams()
    await this.validateTokenValidity()
  },
  data() {
    return {
      cPassword: '',
      password: '',
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
      owner: null,
      token: null,
      locale: null, //lenguage
      isValidatingToken: false,
      isValidQueryParams: false,
      tokenIsValid: false,
      isSettingNewPassword: false,
      isRedirectingToLogin: false
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    ...mapActions('auth', ['tokenResetPasswordValidity','changePassword','discardTokenResetPassword']),
    ...mapMutations('auth', ['undoEmailReset']),

    validateQueryParams(){
      const { owner, token, locale } = this.$route.query
      this.owner = owner
      this.token = token
      this.locale = locale || 'en'
      this.$i18n.locale = this.locale
      localize(this.locale)
      this.isValidQueryParams = this.owner != null && this.token != null
    },
    checkValidateQueryParams(){
      if( !this.isValidQueryParams ){
        this.tokenIsValid = false
        const message = 'Missing parameters in the url, try another new password change request'
        showAlertMessage( 'Missing parameters in the url', 'InfoIcon', message, 'danger', 4500, 'bottom-right')
        setTimeout(() => {
          this.$router.push({name: 'auth-forgot-password'})
        }, 5500)
      }
    },
    async validateTokenValidity(){
      if( this.isValidQueryParams ){
        this.isValidatingToken = true
        const { status, message } = await this.tokenResetPasswordValidity({token: this.token, idOwner: this.owner})
        this.tokenIsValid = status
        this.isValidatingToken = false
        if(status) showAlertMessage( 'Alert', 'InfoIcon', message, 'warning', 5000, 'bottom-right')
        if(!status){
          showAlertMessage( 'Alert', 'InfoIcon', `${message} try another new password change request `, 'danger', 4000, 'bottom-right')
          setTimeout(() => {
            this.$router.push({name: 'auth-forgot-password'})
          }, 4500)
        }
      }
    },
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    async sendResetPassword() {
      const isValidForm = await this.$refs.resetPasswordForm.validate()

      if(isValidForm){
        this.isSettingNewPassword = true

        const payload = { idOwner: parseInt(this.owner), newPassword: this.password, notes: '', idUser:parseInt(this.owner), token: this.token }

        const { status, message } = await this.discardTokenResetPassword(payload)
        if(status){
          showAlertMessage('Request success', 'InfoIcon', message+' Login with your new password', 'success', 4000, 'bottom-right')
          this.isRedirectingToLogin = true
          await this.clearTokenResetPassword(status)
          this.isRedirectingToLogin = false
        } else {
				  showAlertMessage('Error request', 'InfoIcon', message, 'danger', 4000, 'bottom-right')
        }
        this.isSettingNewPassword = false
      }
    },
    async clearTokenResetPassword(status){
      if(status){
        // showAlertMessage('Request success', 'InfoIcon', 'Login with your new password', 'warning', 4000, 'bottom-right')
        Object.assign(this.$data, this.$options.data())//limpio data o reset
        this.$refs.resetPasswordForm.reset()
        this.undoEmailReset() //si cambia el password, reseteo lo que hay en el state
        setTimeout(() => {
          this.$router.push({name: 'auth-login'})
        }, 4500)
      } else showAlertMessage('Error request', 'InfoIcon', message, 'danger', 4000, 'bottom-right')
      return status
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';
.brand-logo svg {
  height: 125px;
  width: 250px;
}
</style>
